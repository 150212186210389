@import 'base.css';
@import 'vendors/index.css';



.bottomBorder{
  border-bottom: 1px solid #b3b3b3;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

[class*="MUIDataTable-tableRoot"] [class*="MUIDataTableHeadCell-sortAction"]{
  align-self: center;
  margin-left: 3px;
}


[class*="MUIDataTable-tableRoot"] [class*="MUIDataTableHeadCell-sortLabelRoot"]{
  background: #565656;
  border-radius: 50%;
  height: 20px;
}

[class*="MUIDataTable-tableRoot"] .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon{
  color: #fff;
  font-size: 12px;
}

.MuiButtonBase-root.MuiButton-root{
  box-shadow: none;
  text-transform: capitalize;
}